@charset "UTF-8";

@font-face {
    src : url("../../../public/Pretendard-1.3.0/public/static/Pretendard-Regular.otf");
    font-style: normal;
    font-family: "Pretendard-Regular"

}
* {
    font-family: 'Pretendard-Regular';
}

/*
태그 초기화
*/
html, body{ width:100%; height:100%; font-weight: 400; font-style: normal; }
body{ height: 100vh; margin:0; color: #222222; overflow-x: hidden;}
div, iframe, h1, h2, h3, h4, h5, h6, p, button, abbr, address, blockquote, q, sub, sup, dl, dt, dd, ol, ul, li, fieldset, form, legend, table, caption, tbody, tfoot, thead, tr, th, td, hr{ padding:0; margin:0; }
header, footer, section, article, aside, nav, hgroup, details, menu, figure, figcaption{ display:block; padding:0; margin:0; }
h1, h2, h3, h4, h5, h6{ font-weight:400; line-height:1; }
ol, ul, li{ list-style:none; }
table{ table-layout:fixed; width:100%; border-collapse:collapse; border-spacing:0; }
th{ font-weight:400; }
form, fieldset, iframe{ border:0; }
img{ border:0; font-size:0; line-height:0; max-width: 100%; }
button{ border:0; }
hr{ border:0; }
i, em, address{ font-style:normal; }
strong, b{ font-weight:500; }
label, button{ cursor:pointer; }
caption, legend{ overflow:hidden; width:0; height:0; font-size:0; line-height:0; text-indent:-50000px; }
input, textarea, select, button, video{ padding:0; font-weight:400; font-size:14px; color:#222222; vertical-align:middle; background:none; border:0; outline:none; }
input::-ms-clear, input::-ms-reveal{ display:none; }
a{ color:#222222; text-decoration:none; outline:none; }
a:hover{ text-decoration:none; }

/*
header
*/
.header {
    height: 100px; display: flex; justify-content: center; background-color: #FFFFFF;
    position: fixed; top: 0; left: 0; right: 0; z-index: 50;
}
.header .top-nav {
    margin: 0 auto; width: 70%; display: flex; align-items: center; justify-content: space-between;
}
.header .top-nav .logoClass {
    width: 145px; height: 40px; left: 312px; top: 30px; float: left;
}
.header .top-nav .menuBtn {
    display: none;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

@media only screen and (max-device-width: 480px){
    .header .top-nav .logoClass  {
        width: 109px; height: 30px; left: -0px; top: 30px; float: left; margin-left:-40px;
    }
    .header {
        height: 60px;
    }
    .header .top-nav .menu {
        display: none;
    }
    .header .top-nav .logoClass svg {
        width: 109px; height: 30px;
    }
}

.header .top-nav .menu {
    float: right;
}

.header .top-nav .menu li {
    float: left; margin-left: 35px; height: 20px; font-weight: 700; font-size: 17px; 
    line-height: 20px; letter-spacing: -0.05em;
}
.header .top-nav .menu a,
.header .top-nav .menu p {
    padding: 0px 3px;
}
.underBar {
    border-bottom: 2px solid #222222;
    padding-bottom: 8px !important;
}
.arrow1 {
    z-index: 1000;
    
    z-index: -1;
    opacity: 0;
}
.arrow-box1 {
    position: absolute; top: 50px; left: 21px;
    display: flex; align-items: center; justify-content: center;
    padding: 13px;
    width: 264px;
    height: 56px;
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 28px;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.05em;
    box-shadow: 0 2px 30px rgba(229, 229, 229, 0.8);
}
.arrow-box1:after {
    position: absolute;
    bottom: 100%;
    transform: translate(-1px, 3px);
    top: -25px;
    left: 130px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    pointer-events: none;
    border-color: rgba(229, 229, 229, 0);
    border-bottom-color: #FFFFFF;
    border-width: 11px;
    margin-left: -20px;
}
.arrow-box1 .icon svg {
    margin-bottom: -7px; margin-left: 3px;
}
.show .arrow-box1,
.show .arrow-box1:after { transition: all .5s; }
.ir {
    cursor: pointer;
}
/* 1은정 */
@media only screen and (max-width: 1600px){
    .header .top-nav {
        margin: 0 50px;
    }
}
@media only screen and (max-width: 1366px){
    .header .top-nav .logoClass svg {
        width: 140px; height: 35px;
    }
    .header .top-nav .menu li {
        font-size: 16px;
    }
    .arrow-box1 {
        left: 17px;
    }
}

@media screen and (max-device-width: 871px) and (min-device-width:481px) {
    .header .top-nav{
        margin: 0; 
    }
    .header .top-nav .menu li {
        font-size: 13px;
    }
    .header .top-nav .logoClass {
        width: 115px;
    }
    .header .top-nav .logoClass svg {
        width: 108px;
    }
    .header .top-nav .menu li {
        margin-left: 10px;
    }
}
@media screen and (max-device-width: 598px) and (min-device-width:481px) {
    .header .top-nav{
        width: 88%; 
    }
}
/* 대웅 */
@media only screen and (max-device-width: 480px){
    .header {
        height: auto;
    }
    .header .top-nav {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        margin: 0px;
    }
    .header .top-nav .logoClass {
        width: 109px;
        height: 30px;
        margin: 15px 0 15px 20px;
    }
    .header .top-nav .logoClass svg{
        width: 109px;
        height: 30px;
    }
    .header .top-nav .menu {
        width: 100%;
        display: none;
        flex-direction: column;
        align-items: center;
    }
    .header .top-nav .menu.active {
        display: flex;
    }
    .header .top-nav .menu ul {
        text-align: center;
        margin-top: 60px;
    }
    .header .top-nav .menu ul li {
        float: none;
        font-size: 22px;
        line-height: 26px;
        margin: 0 0 51px 0;
    }
    .header .top-nav .menu ul li p {
        display: inline;
    }
    .header .top-nav .menuBtn {
        display: block;
        position: absolute;
        right: 20px;
        top: 18px;
    }
    #close {
        display: none;
    }
    .arrow-box1 {
        position: absolute; 
        top: -230px;
        left: -132px;
    }
    .arrow-box1:after {
        left: 142px;
    }
}

/*
footer
*/
.footer {
    background-color: #FAFAFB; width: 100%; height: 300px; display: flex; 
    justify-content: center; padding: 1px; margin-top: 100px;
}
.footer .copyArea {
    margin: 0 auto; width: 1296px; margin-top: 48px;
}
.footer .copyLogo, .footer .copyArea .copyRight {
    display: inline-block; vertical-align: top;
}
.footer .copyLogo .logoClass {
    width: 145px; height: 40px; margin-right: 80px;
}
.footer .copyRight {
    font-size: 15px; line-height: 25px; color: #555555;
}
.footer .copyRight p:last-child {
    font-size: 14px; line-height: 16.8px; margin-top: 24px;
}
.footer .copyRight .copySns {
    margin-top: 40px;
}
.footer .copyRight .copySns a {
    margin-right: 20px;
}
.footer .copyInfo-mo {
    display: none;
}

/* 1은정 */
@media only screen and (max-width: 1600px){
    .footer .copyArea {
        margin: 48px 50px 0px 50px;
    }
}
@media only screen and (max-width: 1366px){
    .footer .copyLogo .logoClass {
        width: 140px; height: 35px;
    }
    .footer .copyRight {
        font-size: 14px; line-height: 25px;
    }
    .footer .copyRight p:last-child {
        font-size: 13px; line-height: 16.8px; margin-top: 24px;
    }
}
/* 대웅 */
@media only screen and (max-device-width: 480px){
    .footer {
        height: 285px;
        margin-top: 60px;
    }
    .footer .copyArea {
        width: 100%;
        margin-top: 40px;
        margin-left: 24px;
    }
    .footer .copyLogo{
        display: block;
        width: 100px;
        height: 28px;
        margin-bottom: 16px;
    }
    .footer .copyLogo .logoClass {
        width: 100px;
        height: 28px;
        margin-right: 80px;
    }
    .footer .copyRight {
        font-size: 11px;
        line-height: 17px;
        letter-spacing: -0.02em;
    }
    .footer .copyInfo {
        display: none;
    }
    .footer .copyInfo-mo {
        display: block;
    }
    .footer .copyRight p:last-child {
        font-size: 11px;
        line-height: 13px;
        letter-spacing: -0.02em;
        margin-top: 18px;
    }
    .footer .copyRight .copySns {
        margin-top: 28px;
    }
    .footer .copyRight .copySns a {
        margin-right: 12px;
    }
}